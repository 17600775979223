import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { FaRegClock, FaUtensils } from 'react-icons/fa'
import { navigate } from 'gatsby'
import vars from '../lib/styled'
import { ButtonLink } from './Button'

const CardWrapper = styled.article`
  margin: 1vw 0 0;
  padding: 0 0.5vw;
  width: 25%;

  @media (max-width: ${vars.breakpoint.tablet}) {
    margin: 2vw 0 0;
    padding: 0 1vw;
    width: ${(props) => (props.featured ? '100%' : '50%')};
  }
`

const CardBody = styled.div`
  background-color: ${vars.contrast};
  padding: 0 1vw 1vh;
  height: 100%;
  text-align: center;
`

const ImageWrapper = styled.div`
  cursor: pointer;
  height: 25vh;
  margin: 1vh -1vw 1vh;
  max-width: none;
  overflow: hidden;
  position: relative;
  width: auto;
`

const Divider = styled.span`
  &::before {
    content: '|';
    margin: 0 0.5rem;
  }
`

const CardTitle = styled.h2`
  font-size: 1.31em;
  margin-bottom: 1rem;

  @media (max-width: ${vars.breakpoint.tablet}) {
    font-size: 1.19em;
    line-height: 1.3;
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 25vh);
`

const Attribute = styled.small`
  color: ${vars.secondary};
  span {
    margin-left: 0.5rem;
  }
`

const Screen = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

const CardRecipe = ({ featured, serves, time, image, title, url, button }) => {
  return (
    <CardWrapper featured={featured}>
      <CardBody>
        <ImageWrapper onClick={() => navigate(`/${url}`)}>
          {image && (
            <Image
              fluid={image.fluid}
              alt={image.title}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </ImageWrapper>
        <CardContent>
          <div>
            <p>
              <Attribute>
                <FaUtensils aria-hidden={true} size="12" />
                <Screen>Makes up to {serves} servings</Screen>
                <span aria-hidden={true}>{serves}</span>
              </Attribute>
              <Divider aria-hidden={true} />
              <Attribute>
                <FaRegClock aria-hidden={true} size="12" />
                <Screen>Ready in {time}</Screen>
                <span aria-hidden={true}>{time}</span>
              </Attribute>
            </p>
            <CardTitle>{title}</CardTitle>
          </div>
          <div style={{ paddingBottom: '2rem' }}>
            <ButtonLink
              to={`/${url}`}
              aria-label={`Click to learn how to make ${title}`}
            >
              {button}
            </ButtonLink>
          </div>
        </CardContent>
      </CardBody>
    </CardWrapper>
  )
}

CardRecipe.propTypes = {
  featured: PropTypes.bool,
  serves: PropTypes.string,
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    fixed: PropTypes.object,
    title: PropTypes.string,
  }).isRequired,
  url: PropTypes.string.isRequired,
}

CardRecipe.defaultProps = {
  featured: false,
  serves: '1+',
}

export default CardRecipe
