import React from 'react'
import { navigate, StaticQuery, graphql } from 'gatsby'
import App from '../components/App'
import Main from '../components/Main'
import { HeaderHeadline } from '../components/Hero'
import Grid from '../components/Grid'
import Wrapper from '../components/Wrapper'
import CardRecipe from '../components/CardRecipe'
import Card from '../components/Card'
import { generateSlug } from '../helpers/slug.js'
import { insertIntoArray } from '../helpers/iterators'
import SEO from '../components/SEO'
import lang from '../../static/lang.json'

const matchProduct = (term, product) =>
  product && 'title' in product
    ? product.title.toLowerCase().includes(term)
    : false

const matchProductCategory = (term, product) =>
  product && 'category' in product
    ? product.category.title.toLowerCase().includes(term)
    : false

const matchTitle = (term, title) => title.toLowerCase().includes(term)

const Search = ({ searchIndex, location, pageContext }) => {
  const products = []
  const recipes = []

  const urlParams = new URLSearchParams(location.search)
  const term = urlParams.get('term') || ''
  const match = term.toLowerCase()
  const locale = pageContext.locale.toLowerCase()

  // manually forward glutten queries
  if (match.includes('gluten-free') || match.includes('gluten free')) {
    navigate('/en-ca/gluten-free')
  } else if (match.includes('sans gluten')) {
    navigate('/fr-ca/sans-gluten')
  }

  const searchResults = searchIndex.filter(
    ({ product, title, node_locale: nl }) => {
      if (nl !== pageContext.locale) return false
      const productArr = product ? product[0] : null
      return (
        matchProduct(match, productArr) ||
        matchProductCategory(match, productArr) ||
        matchTitle(match, title)
      )
    },
  )

  searchResults.forEach((obj) => {
    if (obj.product) {
      if (!obj.product) return
      const product = obj.product[0]

      products.push({
        key: obj.contentful_id,
        name: obj.title,
        image: obj.photo,
        linkText: lang.productView[locale],
        url: generateSlug(
          [
            pageContext.locale,
            lang.products[locale],
            ...insertIntoArray(product, 'category', 'title'),
            ...insertIntoArray(product, 'title'),
            obj.title,
          ].join('/'),
          true,
        ),
      })
    } else {
      recipes.push({
        ...obj,
        key: obj.contentful_id,
        image: obj.photo,
        button: lang.recipesView[locale],
        url: generateSlug(
          [pageContext.locale, lang.recipes[locale], obj.title].join('/'),
        ),
      })
    }
  })

  return (
    <App>
      <SEO title={`Search Results for "${term}"`} />
      <Wrapper>
        <Main style={{ paddingTop: '4rem' }}>
          <HeaderHeadline>
            {term
              ? `${lang.searchResults[locale]} "${term}"`
              : lang.noSearch[locale]}
          </HeaderHeadline>
          <hr aria-hidden="true" className="divider" />
          {term && (
            <>
              {!products[0] && !recipes[0] ? (
                <p>{lang.noResults[locale]}</p>
              ) : null}
              {products[0] && (
                <div>
                  <h2 style={{ marginTop: '2rem' }}>{lang.products[locale]}</h2>
                  <Grid>
                    {products.map((product) => (
                      <Card {...product} />
                    ))}
                  </Grid>
                </div>
              )}
              {recipes[0] && (
                <div>
                  <h2 style={{ marginTop: '2rem' }}>{lang.recipes[locale]}</h2>
                  <Grid>
                    {recipes.map((recipe) => (
                      <CardRecipe {...recipe} />
                    ))}
                  </Grid>
                </div>
              )}
            </>
          )}
        </Main>
      </Wrapper>
    </App>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        variant: allContentfulVariant {
          nodes {
            contentful_id
            node_locale
            title
            product {
              title
              category {
                title
              }
            }
            photo {
              fluid(maxWidth: 500, quality: 100) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }

        recipe: allContentfulRecipe {
          nodes {
            contentful_id
            node_locale
            title
            serves
            time
            photo {
              fluid(maxWidth: 500, quality: 100) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    `}
    render={({ recipe, variant }) => (
      <Search {...props} searchIndex={[...variant.nodes, ...recipe.nodes]} />
    )}
  />
)
